@font-face {
  src: url("../fonts/JetBrainsMono-Regular.woff2");
  font-family: mono;
}

@font-face {
  src: url("../fonts/JetBrainsMono-Italic.woff2");
  font-family: JetbrainsItalic;
}

.App {
  text-align: center;
  align-items: center;
  align-content: center;
}

body {
  background-color: #1B1A1F;
}

.linkInput {
  margin-top: 100px;
  width: 300px;
  height: 30px;
  padding: 0.2em 0.5em;
  font-family: JetBrainsItalic, monospace;
  border-radius: 15px;
  outline: none;
  font-size: 16px
}

.title {
  color: #FFFFFF;
  font-size: xxx-large;
  font-family: JetBrainsItalic, monospace;
}

h1, h2 {
  font-family: mono, monospace;
}

.shortButton {
  height: 50px;
  width: 150px;
  background-color: #322C77;
  color: antiquewhite;
  border: #322C77;
  font-size: medium;
  margin-left: 15px;
  margin-top: 30px;
  font-family: JetBrainsItalic, monospace;
  border-radius: 25px;
  margin-bottom: 200px;
  transition-property: all;
  transition: all ease 1s;
}

.shortButton:hover {
  color: #fff;
  transition: all .3s linear;
  -webkit-transition: all .3s linear;
  -moz-transition: all .3s linear;
  background-color: #001830;
}

.shortButton:nth-child(1):hover {
  background: red;
}

.notFound {
  margin-top: 200px;
  color: #FFFFFF;
}

.title {
  margin-top: 200px;
}

.small-text {
  color: #FFFFFF;
  margin-top: 100px;
  font-family: JetBrainsItalic, monospace;
  margin-bottom: 150px;
}

.href {
  color: #FFFFFF;
  text-decoration: none;
}
